
document.addEventListener("DOMContentLoaded", function () {
    const searchContainer = document.querySelector('.site-search-container') as HTMLElement;
    const searchInput = document.querySelector('.site-search') as HTMLElement;
    const shortcut = searchContainer?.querySelector('kbd') as HTMLElement;

    if (!searchContainer || !searchInput || !shortcut) return;

    const platformKey = shortcut.querySelector('kbd');

    if (platformKey && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
        platformKey.textContent = '⌘';

        if (!searchInput) return;

        searchInput.setAttribute('aria-keyshortcuts', 'Meta+K');
    }

    window.addEventListener('keydown', (e) => {
        if (e.metaKey === true && e.key === 'k') {

            if (!searchInput) return;

            searchInput.focus();
            e.preventDefault();
        }
    });
});
